import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Col, Row, Table } from 'react-bootstrap';
import { HSRRelicSet } from '../../../modules/hsr/common/components/hsr-relic-set';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const HSRGuidesLightConesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Relics</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_relicinfo.png"
            alt="Relics"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Relics</h1>
          <h2>The guide explains the Relic system in Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Relics" />
        <StaticImage
          src="../../../images/starrail/generic/guide_relics.jpg"
          alt="Characters"
        />
        <p>
          Relics are the other type of equipment in this game and serve the
          function of armor and accessories in a traditional RPG game. Equipping
          Relics to your Character will provide them with a variety of stat
          increases from HP to ATK to Speed. Relics will provide your Characters
          with the main bulk of their stat increases.
        </p>
        <p>There are 6 different Relic types:</p>
        <ul>
          <li>Head</li>
          <li>Hands</li>
          <li>Body</li>
          <li>Feet</li>
          <li>Planar Sphere</li>
          <li>Link Rope</li>
        </ul>
        <p>
          Each Relic has one main stat and up to 4 sub-stats. Depending on the
          Relic type, the possible main stats are:
        </p>
        <ul>
          <li>
            <strong>Head</strong> - Flat HP
          </li>
          <li>
            <strong>Hands</strong> - Flat ATK
          </li>
          <li>
            <strong>Body</strong> - HP%, ATK%, DEF%, Crit Rate%, Crit DMG%,
            Outgoing Healing Boost%, Effect Hit Rate%
          </li>
          <li>
            <strong>Feet</strong> - HP%, ATK%, DEF%, Speed
          </li>
          <li>
            <strong>Planar Sphere</strong> - HP%, ATK%, DEF%, Elemental Damage
            Boost*
          </li>
          <li>
            <strong>Link Rope</strong> - HP%, ATK%, DEF%, Break Effect%, Energy
            Regen Rate%
          </li>
        </ul>
        <p>
          *Note that the Elemental Damage Boost will be one of the 7 possible
          elements. It is not an All Elemental Damage Boost.
        </p>
        <p>
          Relic sub-stats are randomly generated,{' '}
          <strong>but they cannot be the same as the Relic’s main stat</strong>.
          The list of possible substats are:
        </p>
        <ul>
          <li>Flat HP</li>
          <li>Flat ATK</li>
          <li>Flat DEF</li>
          <li>HP%</li>
          <li>ATK%</li>
          <li>DEF%</li>
          <li>Crit Rate%</li>
          <li>Crit DMG%</li>
          <li>Effect Hit Rate%</li>
          <li>Effect RES%</li>
          <li>Break Effect%</li>
          <li>Speed</li>
        </ul>
        <p>
          Relics can also be leveled up to increase the main stat bonus, but the
          sub-stats are generated when you obtain the item (or when you enhance
          it) and <strong>they can't be changed</strong>. The maximum level of a
          Relic is determined by its rarity which ranges from 2 Star to 5 Stars.
          The higher the rarity of a Relic, the higher the stats it will have at
          its base level and when upgraded.
        </p>
        <p>
          Also, if the Relic has fewer than 4 sub-stats, it gains a new random
          sub-stat. If the Relict already has 4 sub-stats, a random sub-stat is
          increased by a random amount.
        </p>
        <p>
          The table below shows the Relic information in an easier form to read:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Rarity</th>
              <th>Color</th>
              <th>Max level</th>
              <th>Base number of sub-stats</th>
              <th>Number of unlocks</th>
              <th>Number of upgrades</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="hsr-rar rar-2">2★</span>
              </td>
              <td>Green</td>
              <td>+6</td>
              <td>0</td>
              <td>2</td>
              <td>0</td>
            </tr>
            <tr>
              <td>
                <span className="hsr-rar rar-3">3★</span>
              </td>
              <td>Blue</td>
              <td>+9</td>
              <td>1-2</td>
              <td>2-3</td>
              <td>0-1</td>
            </tr>
            <tr>
              <td>
                <span className="hsr-rar rar-4">4★</span>
              </td>
              <td>Purple</td>
              <td>+12</td>
              <td>2-3</td>
              <td>1-2</td>
              <td>2-3</td>
            </tr>
            <tr>
              <td>
                <span className="hsr-rar rar-5">5★</span>
              </td>
              <td>Gold</td>
              <td>+15</td>
              <td>3-4</td>
              <td>0-1</td>
              <td>4-5</td>
            </tr>
          </tbody>
        </Table>
        <p>
          If you want to learn more about the main and sub-stats (and their
          values), check out our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relic Stats"
            link="/star-rail/guides/relic-stats"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicstats.png"
                alt="Relic Stats"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Set effects" />
        <p>
          Relics also come in sets and equipping multiple Relics from the same
          set will provide additional bonuses for the Character. Though it's
          important to note that there are 2 types of sets:
        </p>
        <ul>
          <li>
            <strong>Relics Sets</strong> - those sets give a 2 and 4-piece bonus
            and they only appear on Head, Hands, Armor, and Boots Relics.
          </li>
          <li>
            <strong>Planetary Ornaments Sets</strong> - those sets give only a
            2-piece bonus and they appear on the Planar Sphere and the Link Rope
            Relics only.
          </li>
        </ul>
        <p>
          This means that you're only able to combine one Relic Sets and one
          Planetary Ornament Set.
        </p>
        <p>Here's how some Relic Sets look like:</p>
        <Row xs={1} xxl={2} className="relic-row">
          <Col>
            <HSRRelicSet name="Musketeer of Wild Wheat" />
          </Col>
          <Col>
            <HSRRelicSet name="Guard of Wuthering Snow" />
          </Col>
          <Col>
            <HSRRelicSet name="Fleet of the Ageless" />
          </Col>
          <Col>
            <HSRRelicSet name="Celestial Differentiator" />
          </Col>
        </Row>
        <p>For the full list of available Relic sets go here:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relic Sets"
            link="/star-rail/guides/relic-sets"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relics.png"
                alt="Relic Sets"
              />
            }
          />
        </Row>
        <SectionHeader title="Crafting" />
        <p>
          Once you reach a certain point in the game (TR 40) you will unlock
          Relic Synthesis. The system allows you craft any Relic Type and Set
          you want by scrapping other Relics - you need to scrap 10 pieces to be
          able to craft a new one. Still, the issue is that the Main Stat of the
          Relic will be random, unless you use Self-Modeling Resin.
        </p>
        <p>Check the video below to learn more about the crafting system:</p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4NkRu1vBqvA" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesLightConesPage;

export const Head: React.FC = () => (
  <Seo
    title="Relics | Honkai: Star Rail | Prydwen Institute"
    description="The guide explains the Relic system in Honkai: Star Rail."
  />
);
